import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faExternalLinkAlt,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from "react-loader-spinner";
import useFetchAllOrder from "../../../CustomHooks/FetchAllOrder";
import Createpool from "../../CreatePool/createpool";
import StorageAbi from "../../../utils/StorageAbi";
import { ethers } from "ethers";
import Alerts from "../../Alerts/alert";

function ManagePools() {
  const [AllOrders] = useFetchAllOrder();
  const [addPool, setAddPool] = useState(false);

  const [alert, setAlert] = useState("");

  useEffect(() => {}, [alert]);
  const FetchProvider = async (tokenAdd, Abi) => {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum || process.env.REACT_APP_TESTNET_RPC_URL
    );
    const signer = provider.getSigner();

    if (ethers.utils.isAddress(tokenAdd)) {
      var address = tokenAdd;
      var contract = new ethers.Contract(address, Abi, signer);
      console.log(contract);
      return contract;
    } else {
      console.log("You Enter Invalid   Address");
    }
  };

  const handleEnable = async (e) => {
    console.log("eeeeeeeee", e);
    const provider = new ethers.providers.Web3Provider(
      window.ethereum || process.env.REACT_APP_TESTNET_RPC_URL
    );
    const { chainId } = await provider.getNetwork();
    console.log("chain id", chainId);
    const { ethereum } = window;
    if (e.network === "ETH") {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x4" }],
        });
        const StorageAddress = process.env.REACT_APP_STORAGE_ADDRESS_ETH;
        const contract = await FetchProvider(StorageAddress, StorageAbi);
        console.log(contract);

        const enable = !e.isEnable;

        console.log("enable", enable);
        console.log("enable", e.poolId);

        const statusChnage = await contract.setIsDeleted(
          parseInt(e.poolId),
          enable
        );

        const receipt = await statusChnage.wait();

        if (receipt) {
          console.log("receipt", receipt);
          AlertNotify(`Successfully change Pool Status`, 3000);
          window.location.href = "http://ultrapad.finance/admin/managepools";
        }
      } catch (addError) {
        console.error("error in switching network", addError);
      }
    } else if (e.network === "BSC") {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x61" }],
        });
        const StorageAddress = process.env.REACT_APP_STORAGE_ADDRESS_BSC;
        const contract = await FetchProvider(StorageAddress, StorageAbi);
        console.log(contract);

        const enable = !e.isEnable;

        console.log("enable", enable);
        console.log("enable", e.poolId);

        const statusChnage = await contract.setIsDeleted(
          parseInt(e.poolId),
          enable
        );

        const receipt = await statusChnage.wait();
        if (receipt) {
          console.log("receipt", receipt);
          AlertNotify(`Successfully change Pool Status`, 3000);
          window.location.href = "http://ultrapad.finance/admin/managepools";
        }
      } catch (addError) {
        console.error("error in switching network", addError);
      }
    }
    // const contract=await FetchProvider(e.poolingToken,)
    // console.log(contract);
    // setIsDeleted()
  };

  const handleCloseAddPool = () => {
    setAddPool(false);
  };

  const AlertNotify = (message, time) => {
    setAlert(message);

    setTimeout(() => {
      setAlert("");
    }, time);
  };
  return (
    <div className="ultrapad-w-100">
      <div className="content">
        <div className="manage-pools-pg">
          {alert !== "" ? <Alerts message={alert} show={true} /> : <></>}
          {addPool ? (
            <>
              <Card className="pt-lg-3 pt-3 h-auto" id="style-6">
                {/* <Button
                  onClick={() => setAddPool(false)}
                  className='text-white light-blue-btn text-capitalize d-inline w-md-25'
                >
                  <FontAwesomeIcon className='add-icon' icon={faBackward} />{' '}
                  Back to Pools
                </Button> */}
                <Card.Header className="titles-sett">
                  <Createpool handleCloseAddPool={handleCloseAddPool} />
                </Card.Header>
              </Card>
            </>
          ) : (
            <Card className="pt-lg-4 pt-3 h-auto" id="style-6">
              <Card.Header className="titles-sett">
                <h2 className="text-shadow">Manage Pools</h2>
                <div className="sm-right-btn">
                  <Button
                    className="d-inline light-blue-btn text-capitalize"
                    onClick={() => setAddPool(true)}
                  >
                    <FontAwesomeIcon className="add-icon" icon={faPlus} /> Add
                    Pool
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                {AllOrders.length > 0 ? (
                  <>
                    <div className="table-responsive manage-pools">
                      <Table>
                        <thead>
                          <tr>
                            {/* <th>Pool #</th> */}
                            <th>Pool</th>
                            <th>Name</th>
                            <th>Network</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AllOrders.map((item, index) => {
                            return (
                              <tr key={index}>
                                {/* <td>{item.poolId}</td> */}
                                <td>
                                  {" "}
                                  <Link
                                    to={`/pool:${item.poolingToken}`}
                                    style={{ color: "white" }}
                                  >
                                    <FontAwesomeIcon
                                      className="del-icon"
                                      icon={faExternalLinkAlt}
                                    />{" "}
                                    0x...{item.poolingToken.substring(39, 42)}
                                  </Link>
                                </td>
                                <td>{item.idoName}</td>

                                <td>{item.network}</td>

                                <td>{item.startDate}</td>
                                <td>{item.endDate}</td>
                                <td>
                                  {item.isEnable ? (
                                    <Badge className="px-2 py-1" bg="info">
                                      Active
                                    </Badge>
                                  ) : (
                                    <Badge className="px-2 py-1" bg="secondary">
                                      Disabled
                                    </Badge>
                                  )}{" "}
                                </td>
                                <td>
                                  <button
                                    className="del_btn"
                                    onClick={() => {
                                      handleEnable(item);
                                    }}
                                  >
                                    {item.isEnable ? (
                                      <FontAwesomeIcon
                                        className="del-icon"
                                        icon={faTimes}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="del-icon"
                                        icon={faCheck}
                                      />
                                    )}
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <div className="text-center faq-loader">
                    <TailSpin
                      height="100"
                      width="100"
                      color="#46bdf4"
                      ariaLabel="loading"
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}
export default ManagePools;
