import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header";
// import Footer from './components/Footer'
import Landing from "./components/landingPage/landingPage";
import Product from "./components/ProductPage/ProductPage";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Privacy/Privacy";
import Faqs from "./components/FaqsPage/Faqs";
import Admin from "./components/AdminPanel/admin";
import useGetOwner from "./CustomHooks/GetOwner";
import { useCookies } from "react-cookie";
import { ethers } from "ethers";
import Alerts from "./components/Alerts/alert";
import Helmet from "react-helmet";
import axios from "axios";

function App() {
  const [cookies, setCookies] = useCookies(["address"]);
  const [alert, setAlert] = useState("");
  const [ownerFlag, setOwnerFlag] = useGetOwner(cookies.address);
  const [settingDetail, setSettingDetails] = useState({});

  useEffect(() => {
    async function fetch() {
      const { ethereum } = window;
      if (ethereum) {
        var provider =
          new ethers.providers.Web3Provider(ethereum) ||
          "https://data-seed-prebsc-1-s1.binance.org:8545/";

        const isMetaMaskConnected = async () => {
          const accounts = await provider.listAccounts();
          return accounts.length > 0;
        };

        await isMetaMaskConnected().then(async (connected) => {
          if (connected) {
            console.log("MetamasK connected ");
            // ethereum.request({
            //   method: 'wallet_switchEthereumChain',
            //   params: [{ chainId: '0x61' }]
            // })
            const accounts = await ethereum.request({
              method: "eth_requestAccounts",
            });
            if (accounts !== null) {
              setCookies("address", accounts[0]);
            }
          } else {
            AlertNotify("Connect Your Metamask", 3000);
          }
        });
      } else {
        console.log("Error in Getting metamask");
        AlertNotify(
          `Metamask is not Installed.Install metamask from ${"https://chrome.google.com/webstore/detail/metamask"}`,
          10000
        );
      }
    }
    fetch();
  }, [cookies.address, setCookies, setOwnerFlag]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/site_settings`)
      .then((res) => {
        console.log("setting responce", res.data.data);
        setSettingDetails(res.data.data);
      })
      .catch((err) => {
        console.log("Error in  fetching settings Component", err);
      });
  }, []);

  const AlertNotify = (message, time) => {
    setAlert(message);

    setTimeout(() => {
      setAlert("");
    }, time);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`${settingDetail.site_description}`}
        />
        <meta name="keywords" content={`${settingDetail.meta_keywords}`} />
        {/* twitter */}

        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:site" content={`${settingDetail.site_title}`} /> */}
        {/* <meta name="twitter:title" content={`${settingDetail.site_title}`} /> */}
        {/* <meta name="twitter:url" content={`${window.location.origin.toString()}`} />
        <meta name="twitter:image" content={`${window.location.origin.toString()}/ultrapad.png`} /> */}
        <meta
          name="twitter:description"
          content={`${settingDetail.meta_description}`}
        />
        <meta name="twitter:creator" content={`${settingDetail.site_title}`} />

        {/* twitter */}

        {/* facebook */}
        <meta name="author" content={`${settingDetail.site_title}`} />
        <meta property="og:title" content={`${settingDetail.site_title}`} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content={`${window.location.origin.toString()}`} />
        <meta property="og:image" content={`${window.location.origin.toString()}/ultrapad.png`} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" /> */}
        <meta
          property="og:description"
          content={`${settingDetail.meta_description}`}
        />

        {/* facebook */}
      </Helmet>
      <div className="App">
        <div className="wrapper">
          {alert !== "" ? <Alerts message={alert} show={true} /> : <></>}
          <Header />

          <main>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/pool:id" exact element={<Product />} />
              <Route path="/tos" exact element={<Terms />} />
              <Route path="/privacy" exact element={<Privacy />} />
              <Route path="/faqs" exact element={<Faqs />} />
              {ownerFlag === true ? (
                <>
                  {" "}
                  <Route path="/admin/*" exact element={<Admin />} />
                </>
              ) : (
                <></>
              )}
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
}

export default App;
