import { useState, useEffect, useCallback } from "react";
import { createClient } from "urql";
function useFetchIdoCounts() {
  const API_URL = process.env.REACT_APP_SUBGRAPH_API_LATEST_BSC;
  const API_URL_ETH = process.env.REACT_APP_SUBGRAPH_API_LATEST_ETH;

  const [totalIdo, setTotalIdo] = useState({
    live: 0,
    upcoming: 0,
    ended: 0,
  });
  const stateSetter = useCallback(
    (end, live, upcom) => {
      setTotalIdo({
        ...totalIdo,
        ended: end,
        upcoming: upcom,
        live: live,
      });
    },
    [totalIdo]
  );
  const FetchCounts = useCallback(() => {
    async function EffectSolver() {
      var timestamp = await Math.round(+new Date() / 1000);
      console.log("fetchCounts");
      const Ended = `query{
          poolInfos (where:{startDate_lt: ${timestamp},  endDate_lt:${timestamp}  , isEnable:true } ) {
              poolId        
              }   
          }`;

      const Upcoming = `query{
            poolInfos(where:{startDate_gt: ${timestamp},  endDate_gt:${timestamp}, isEnable:true  } ) {
                poolId        
                }
              
           
            }`;
      const Live = `query{
              poolInfos(where:{startDate_lt: ${timestamp},  endDate_gt:${timestamp} , isEnable:true   } ) {
                  poolId        
                  }              
             
              }`;

      const client2 = createClient({
        url: API_URL,
      });
      const clientETH = createClient({
        url: API_URL_ETH,
      });

      const end = await client2.query(Ended).toPromise();
      const endETH = await clientETH.query(Ended).toPromise();

      // console.log("ended ", end.data.poolInfos.length);

      const upcom = await client2.query(Upcoming).toPromise();
      const upcomETH = await clientETH.query(Upcoming).toPromise();

      // console.log("upcom", upcom.data.poolInfos.length);

      const live = await client2.query(Live).toPromise();
      const liveETH = await clientETH.query(Live).toPromise();

      // console.log("live ", live.data.poolInfos.length);

      if (
        end.data !== undefined &&
        live.data !== undefined &&
        upcom.data !== undefined &&
        endETH.data !== undefined &&
        upcomETH.data !== undefined &&
        liveETH.data !== undefined
      ) {
        stateSetter(
          end.data.poolInfos.length + endETH.data.poolInfos.length,
          live.data.poolInfos.length + liveETH.data.poolInfos.length,
          upcom.data.poolInfos.length + upcomETH.data.poolInfos.length
        );
      }
      console.log("i am in fetch Counts");
    }
    EffectSolver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_URL, stateSetter]);
  useEffect(() => {
    FetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [totalIdo];
}

export default useFetchIdoCounts;
