import React, { useState } from 'react'
import Icon2 from '../../assets/images/icon-2.png'
import Icon1 from '../../assets/images/icon-1.png'
import Twitter from '../../assets/images/twiiter.png'
import Icon4 from '../../assets/images/icon-4.png'
import Icon5 from '../../assets/images/horn.svg'
import { ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { DefualtImageSetter } from "../../utils/globalHelpers"
import { TailSpin } from 'react-loader-spinner'
function IdoCards({ idoArr, currentIdoLength }) {
  // console.log(idoArr)
  const socialLinks = [Icon1, Icon2, Twitter, Icon5, Icon4]
  //   console.log('ido arr in cards', idoArr)

  const [loadMoreIdo, setLoadMoreIdo] = useState(4)

  return (
    <div className='row'>
      {idoArr.slice(0, loadMoreIdo).map((item, index) => {
        return (
          <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-md-4 mb-3' key={index}>
            <Link
              to={`/${'pool'}:${item.idoAddress}`}
              className='card mb-3'
            //   state={{ item: item }} 
            >
             <div className='d-flex justify-content-between flex-column h-100'>
              <div className='d-block'>
              <div className='d-flex align-items-lg-start ' animation='wave'>
                <figure className='mb-0 game-img'>
                  <img
                    className='img-fluid profile'
                    src={item.logoHash}
                    alt='Gammes'
                    onError={DefualtImageSetter}
                  />
                </figure>

                <div className='w-100'>
                  <p className='card-title s-line first mb-1'>
                    {item.idoName}
                  </p>
                  <div className='d-flex flex-column'>
                    <p className='text-capitalize s-line second mb-1'>
                      {item.idoName}
                    </p>
                    {/* <p className='text-capitalize fw-bold ido-bold'>starts: <span className='date-color'>{item.startDate}</span> </p> */}
                    {/* <a href="#" href="#" className='tag-btn text-uppercase'>upcoming</a> */}
                  </div>
                </div>
              </div>
              <div className='social-icon-bar'>
                <ul>
                  {item.socials.social.map((item, index) => {
                    return (
                      <li key={index}>
                        <figure className='mb-0'>
                          <img
                            className='img-fluid'
                            src={`${socialLinks[index]}`}
                            alt='Gammes'
                          />
                        </figure>
                      </li>
                    )
                  })}
                </ul>
                
              </div>
              <div className='progress-bar'>
                <div className='prog-text d-flex '>
                  <p className='mb-1'>
                    {item.remainingHours.day} Days and{' '}
                    {item.remainingHours.hours} Hours
                  </p>
                </div>
                <div className='mb-1'>
                  <ProgressBar
                    now={item.salePercent}
                    style={{ color: 'pink' }}
                  />
                </div>
                <div className='d-flex justify-content-between'>
                  <p className='game-price'>0 {item.idoName}</p>
                  <p>{/* {item.initialSupply} {item.name} */}</p>
                </div>
                {/* <p className='text-capitalize fw-bold ido-bold'>starts: <span className='date-color'>{item.startDate}</span> </p> */}
              </div>   
              </div>
              <div><p className='text-capitalize fw-bold ido-bold'>starts: <span className='date-color'>{item.startDate}</span> </p>
              <div className='card-footer'>
                <div className='card-footer-content'>
                  <ul>
                    {/* <li>
                      <p className='text-capitalize fw-bold ido-bold'>starts</p>
                      <div className='d-flex'>
                        <strong className='d-block feature-price blue'>
                          {item.startDate}
                        </strong>
                        <span className='text-capitalize'>
                          {time.hour}:{time.min} UTC
                        </span>
                      </div>
                    </li> */}
                    <li>
                      <p className='text-capitalize fw-bold ido-bold'>price</p>
                      <div className='d-flex align-items-baseline'>
                        <strong className='d-block feature-price purple me-2'>
                          {item.price}
                        </strong>
                        <span className='text-capitalize'> <p>= {item.price} $</p></span>
                      </div>
                    </li>
                    <li>
                      <p className='text-capitalize fw-bold ido-bold'>total raise</p>
                      <div>
                        <strong className='d-block feature-price pink'>
                          ${item.totalRaised}
                        </strong>
                      </div>
                    </li>
                  </ul>
                </div>
              </div></div>
             </div>
              
            </Link>
          </div>
        )
      })}
      {currentIdoLength !== idoArr.length && (
        <div className='text-center d-flex justify-content-center mb-2'>
          <TailSpin
            height='50'
            width='50'
            color='#46bdf4'
            ariaLabel='loading'
          />
        </div>
      )}
      {(currentIdoLength > loadMoreIdo) &&
        <div className="d-flex justify-content-center align-items-center">
          {" "}
          <button
            className="light-blue-btn"
            onClick={() => setLoadMoreIdo(loadMoreIdo + 4)}
          >
            Load More
          </button>
        </div>}
    </div>
  )
}

export default IdoCards
