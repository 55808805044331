import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Row, Col, Form } from 'react-bootstrap'
import Alerts from '../../Alerts/alert'
import { CKEditor  } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios'
function Setting() {
  const [settingDetail, setSettingDetails] = useState({
    site_title: '',
    site_description: '',
    meta_title: '',
    meta_keywords: '',
    telegram_link: '',
    medium_link: '',
    twitter_link: '',
    website_link: '',
    aap_link: '',
    poscast_link: '',
    meta_description: ''
  })
  const [alert, setAlert] = useState('')

  const [formValidate, setFormValidate] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    setSettingDetails({ ...settingDetail, [name]: value })

    if (!!formValidate[name]) setFormValidate({
      ...formValidate,
      [name]: null
    })
  }

  const findFormErrors = () => {
    const { site_title, telegram_link, medium_link, twitter_link, website_link, aap_link, poscast_link } = settingDetail
    const newErrors = {}

    const regex = new RegExp(
      "https?://(www.)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    // site_title errors
    if (!site_title || site_title === '') newErrors.site_title = 'cannot be blank!'
    // site_description errors
    // if (!site_description || site_description === '') newErrors.site_description = 'cannot be blank!'
    // meta_title errors
    // if (!meta_title || meta_title === '') newErrors.meta_title = 'cannot be blank!'
    // meta_keywords errors
    // if (!meta_keywords || meta_keywords === '') newErrors.meta_keywords = 'cannot be blank!'
    // telegram_link errors
    if (telegram_link && !regex.test(telegram_link)) newErrors.telegram_link = 'Please Enter a Valid Link!'
    // medium_link errors
    if (medium_link && !regex.test(medium_link)) newErrors.medium_link = 'Please Enter a Valid Link!'
    // twitter_link errors
    if (twitter_link && !regex.test(twitter_link)) newErrors.twitter_link = 'Please Enter a Valid Link!'
    // website_link errors
    if (website_link && !regex.test(website_link)) newErrors.website_link = 'Please Enter a Valid Link!'
    // aap_link errors
    if (aap_link && !regex.test(aap_link)) newErrors.aap_link = 'Please Enter a Valid Link!'
    // poscast_link errors
    if (poscast_link && !regex.test(poscast_link)) newErrors.poscast_link = 'Please Enter a Valid Link!'
    // meta_description errors
    // if (!meta_description || meta_description === '') newErrors.meta_description = 'cannot be blank!'

    return newErrors
  }

  const SaveSettings = () => {
    // const {site_title,}

    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormValidate(newErrors)
    } else {
      axios
        .post(`${process.env.REACT_APP_ADMIN_API}/site_settings`, {
          site_title: settingDetail.site_title,
          site_description: settingDetail.site_description,
          meta_title: settingDetail.meta_title,
          meta_keywords: settingDetail.meta_keywords,
          meta_description: settingDetail.meta_description,
          telegram_link: settingDetail.telegram_link,
          medium_link: settingDetail.medium_link,
          twitter_link: settingDetail.twitter_link,
          website_link: settingDetail.website_link,

          aap_link: settingDetail.aap_link,
          poscast_link: settingDetail.poscast_link
        })
        .then(res => {
          console.log('setting responce', res.data.data)
          setSettingDetails(res.data.data)
          setAlert('SuccessFully Save Setting.')
          setTimeout(() => {
            setAlert('')
          }, 888000)
        })
        .catch(err => {
          console.log('Error in  fetching settings Component', err)
          setAlert('Unable to Edit Settings Check for Error')


          setTimeout(() => {
            setAlert('')
          }, 5000)
        })
    }

  }
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/site_settings`)
      .then(res => {
        console.log('setting responce', res.data.data)
        setSettingDetails(res.data.data)
      })
      .catch(err => {
        console.log('Error in  fetching settings Component', err)
      })
  }, [])

  return (
    <div className='ultrapad-w-100'>
      <div className='content'>
        <div className='cms-pg'>
        {alert !== '' ? <Alerts message={alert} show={true} /> : <></>}
          <Card className='pt-lg-4 pt-3 h-100' id="style-6">
            <Card.Header className='titles-sett text-center'>
              <div className='d-flex flex-sm-row flex-column justify-content-sm-between w-100'>
                <h2 className='text-shadow mb-sm-0 mb-2'> Site Settings</h2>
                <div>
                  <div className='sm-right-btn'><button className='d-inline light-blue-btn text-capitalize mb-sm-0 mb-2' onClick={SaveSettings}>
                    Save Settings</button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='cms-pages-style'>
                <div className='container'>
                  <Row>
                    <Col sm={12}>
                      <div className='cms-pages-style text-secondary'>
                        <Form>
                          <Form.Group className='mb-2'>
                            <Form.Label>Site Title</Form.Label>
                            <Form.Control
                              type='text'
                              name='site_title'
                              onChange={handleChange}
                              placeholder='Enter Name'
                              className={'text-white'}
                              value={settingDetail.site_title}
                              isInvalid={!!formValidate.site_title}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.site_title}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Meta Title</Form.Label>
                            <Form.Control
                              type='text'
                              name='meta_title'
                              className={'text-white'}
                              onChange={handleChange}
                              placeholder='Enter meta_title'
                              value={settingDetail.meta_title}
                              isInvalid={!!formValidate.meta_title}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.meta_title}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Meta keywords</Form.Label>
                              <Form.Control
                                type='text'
                                name='meta_keywords'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter meta_keywords'
                                value={settingDetail.meta_keywords}
                                isInvalid={!!formValidate.meta_keywords}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.meta_keywords}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Label>Site Description</Form.Label>
                            <span className="ido-ckeditor setting-ckeditor">
                              <CKEditor editor={ClassicEditor} data={settingDetail == null ? '' : settingDetail} onChange={ ( event, editor2 ) => {setSettingDetails(editor2.getData())}}/>
                            </span>
                            {/* <Form.Control id="scroll-1"
                              as='textarea'
                              rows={2}
                              name='site_description'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Description'
                              value={settingDetail.site_description}
                              isInvalid={!!formValidate.site_description}
                            /> */}
                            <Form.Control.Feedback type='invalid'>{formValidate.site_description}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className='mb-3'>
                            <Form.Label>Meta Description</Form.Label>
                            <Form.Control id="scroll-1"
                              as='textarea'
                              rows={2}
                              name='meta_description'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Description'
                              value={settingDetail.meta_description}
                              isInvalid={!!formValidate.meta_description}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.meta_description}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Twitter Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='twitter_link'
                              onChange={handleChange}
                              placeholder='Enter Twitter Link'
                              className={'text-white'}
                              value={settingDetail.twitter_link}
                              isInvalid={!!formValidate.twitter_link}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.twitter_link}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Telegram Link</Form.Label>
                            <Form.Control
                              type='link'
                              onChange={handleChange}
                              name='telegram_link'
                              className={'text-white'}
                              placeholder='Enter Telegram Link'
                              value={settingDetail.telegram_link}
                              isInvalid={!!formValidate.telegram_link}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.telegram_link}</Form.Control.Feedback>
                          </Form.Group>{' '}
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Medium Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='medium_link'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Medium Link'
                              value={settingDetail.medium_link}
                              isInvalid={!!formValidate.medium_link}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.medium_link}</Form.Control.Feedback>
                          </Form.Group>{' '}
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>App Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='aap_link'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter App Link'
                              value={settingDetail.aap_link}
                              isInvalid={!!formValidate.aap_link}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.aap_link}</Form.Control.Feedback>
                          </Form.Group>{' '}
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Podcast Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='poscast_link'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Podcast Link'
                              value={settingDetail.poscast_link}
                              isInvalid={!!formValidate.poscast_link}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.poscast_link}</Form.Control.Feedback>
                          </Form.Group>{' '}
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Website Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='website_link'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Website Link'
                              value={settingDetail.website_link}
                              isInvalid={!!formValidate.website_link}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.website_link}</Form.Control.Feedback>
                          </Form.Group>{' '}
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>

          </Card>
        </div>
      </div>
    </div>
  )
}

export default Setting
