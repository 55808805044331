import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
// import rightImg from '../../assets/images/bg-svg.jfif'

import axios from 'axios'
const Banner = () => {
  const [tokenInfo, setTokenInfo] = useState({})

  const [copySuccess, setCopySuccess] = useState('')
  const textAreaRef = useRef(null)
  const [ setCopyCheck] = useState(false)

  async function copyToClipboard (e) {
    console.log(e)
    try {
      await navigator.clipboard.writeText(e)
      setCopyCheck(true)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
    setCopySuccess('Copied!')
  }
  useEffect(() => {
    let dummyObj = {}

    axios.get(process.env.REACT_APP_COIN_API).then(res => {
      const {
        current_price,
        market_cap,
        total_supply,
        total_value_locked
      } = res.data.market_data

      dummyObj.price = current_price.usd.toFixed(6)
      dummyObj.market_cap = market_cap.usd
      dummyObj.supply = total_supply
      if (total_value_locked === null) {
        dummyObj.tvl = 0
      } else {
        dummyObj.tvl = total_value_locked
      }
      setTokenInfo(dummyObj)
    })
  }, [])
  return (
    <>
      <section className='hero-banner'>
        <div className='container-fluid custom-block'>
          <div className='banner-text'>
            <div className='text-wrapper'>
              <div className='text-wrapper'>
                <span className='banner-span text-uppercase'>
                  safest launchpad
                </span>
                <h1 className='banner-h1 text-uppercase'>multi-chain</h1>
              </div>
              <p className='light-p'>
                Launching hand-picked high-quality projects on the Blockchain.
              </p>
              <p className='p'>
                Stake UltraPad Finance tokens to get early-access to promising projects.
              </p>
            </div>
            <div className='block-btn text-wrapper'>
              <a
                className='d-inline shadow-btn text-capitalize'
                href={process.env.REACT_APP_Web_link}
              >
                apply as a project
              </a>
              {/* <a
                className='d-inline light-blue-btn text-capitalize'
                href={process.env.REACT_APP_Web_link}
              >
                announcements
              </a> */}
              <a
                className='d-inline blue-outline-btn text-capitalize'
                href='https://telegram.org/'
                target="_blank"
                rel="noopener noreferrer"
              >
                telegram
              </a>
            </div>
            <div className='clipboard'>
              <div className='clipboard-flex d-flex align-items-center'>
                <div className='block-btn'>
              
                  <button
                    className='d-inline blue-outline-btn text-capitalize'
                    onClick={() => window.open(`https://pancake.kiemtienonline360.com/#/swap?outputCurrency=${process.env.REACT_APP_TOKEN_Address}`,'_blank')}
                  >
                    Buy{" "} <span className='text-uppercase'> {" "}UltraPad</span>
                  </button>
                </div>
                <div className='d-flex align-items-center'>
                  <div>
                  <span
                    ref={textAreaRef}
                  
                  >
                    {process.env.REACT_APP_TOKEN_Address}
                  </span>
                  </div>
                  {copySuccess === '' ? (
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={() => {
                        copyToClipboard(`${process.env.REACT_APP_TOKEN_Address}`)
                      }}
                    />
                  ) : (
                    <div ><p className='mb-0' style={{marginLeft:"10px"}}>{copySuccess}</p>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className='market-cap'>
          <div className='container-fluid custom-block'>
              <div className='market-cap-inner m-auto'>
              <div className="titles-sett card-header justify-content-center">
                  <h2 className="text-shadow text-center">UltraPad Finance – You Can Never Be In Lotss!  </h2>
              </div>
                {/* <div>
                  <p classNameName='text-white qoute-p'>
                  <ul>
                    <li>  UlraPad Finance – You Can Never Be In Lotss!  </li>
                    <li>Build Your Crypto Empire with UltraPad</li>
                    <li>Make Quick Bucks in the Crypto World</li>
                    <li>UltraPad Finance – A Wild Crypto Ride</li>
                  </ul> </p>
                </div> */}
                <blockquote>
                 <div>
                 <div className="titles-sett card-header py-0 mt-3 justify-content-center">
                    <label className="form-label purple text-center">Description/Introductory para   ${tokenInfo.market_cap}</label>
              </div>
                 <p className='text-white qoute-p mb-3 my-2'> Looking to get into the world of cryptocurrency investment/fundraising? UltraPad Finance is just the platform for you! Featuring an industry-leading launchpad with pooling, staking, guaranteed allocations, lottery, and more, UltraPad is a decentralized platform on the Ethereum blockchain that’s a perfect way to get started with cryptocurrency investment.</p>
                  <p className='text-white qoute-p'>
                  Based on the crowdfunding technique, this innovative launchpad will enable cryptocurrency projects to introduce native coins/tokens through DEXs (decentralized exchanges).  And with our Initial Dex Offering (IDO), you can be sure to get in on the ground floor of some of the hottest new projects out there. So why wait? Sign up today and see how UltraPad Finance can help you take your first steps into the exciting crypto world! 
                  </p>
                 </div>
                </blockquote>
                  {/* <ul>
                    <li>
                      <strong className='d-block price'>${tokenInfo.price}</strong>
                      <p className='mb-0'>Price</p>
                    </li>
                    <li>
                      <strong className='d-block price'>
                        ${tokenInfo.market_cap}
                      </strong>
                      <p className=' mb-0'>
                        Market Cap (Supply:{tokenInfo.supply})
                      </p>
                    </li>
                    <li>
                      <strong className='d-block price'>${tokenInfo.tvl}</strong>
                      <p className=' mb-0'>TVL</p>
                    </li>
                    <li>
                      <strong className='d-block price'>$0.91</strong>
                      <p className=' mb-0'>Raised in 103 projects</p>
                    </li>
                  </ul> */}
                </div>
            
          </div>
        </section>
      </section>
    </>
  )
}

export default Banner
